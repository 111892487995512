
  /* eslint-disable */
  import Vue, { PropType } from 'vue';
  import { mapGetters } from 'vuex';
  import { Payment } from '@/components/shared/payment';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'es-credit-card-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },

    components: {
      'es-payment': Payment,
    },

    computed: {
      ...mapGetters({
        isFetching: 'cards/isFetching',
      }),
    },
  });
