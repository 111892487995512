
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'es-client-recommendations',

    data: () => ({
      code: '',
    }),

    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
    },

    watch: {
      getUser(newVal) {
        if (newVal) {
          this.code = newVal.referral_code;
        }
      },
    },

    created() {
      this.fetchUser();
    },

    methods: {
      ...mapActions({
        fetchUser: 'session/getUser',
      }),
      onCopy(): void {
        const fullLink = `https://ease.ro?referral_code=${this.code}`;
        copyToClipboard(fullLink);
        (this as any).$toasts.toast({
          id: nanoid(),
          message: this.$t('toast.referral_link_copied'),
          title: this.$t('toast.success_title'),
          intent: 'success',
        });
      },
    },
  });
