
  import Vue from 'vue';
  import { nanoid } from 'nanoid';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'es-client-credits',

    data: () => ({
      code: '',
    }),

    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
        getCreditEaseHistory: 'giftCards/getCreditEaseHistory',
        getAppliedGiftCard: 'giftCards/getAppliedGiftCard',
      }),
    },

    watch: {
      getAppliedGiftCard(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchUser();
          (this as any).$toasts.toast({
            id: nanoid(),
            intent: 'info',
            title: 'Felicitari!',
            message: 'Codul a fost activat cu success!',
          });
        }
      },
    },

    created() {
      this.fetchCreditOrderHistory();
    },

    methods: {
      ...mapActions({
        fetchCreditOrderHistory: 'giftCards/fetchCreditOrderHistory',
        applyGiftCard: 'giftCards/applyGiftCard',
        fetchUser: 'session/getUser',
      }),
      async onValidate() {
        await this.applyGiftCard(this.code);
      },
    },
  });
