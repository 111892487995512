import { render, staticRenderFns } from "./ClientRecommendations.vue?vue&type=template&id=1ce7ad57&scoped=true&"
import script from "./ClientRecommendations.vue?vue&type=script&lang=ts&"
export * from "./ClientRecommendations.vue?vue&type=script&lang=ts&"
import style0 from "./ClientRecommendations.vue?vue&type=style&index=0&id=1ce7ad57&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce7ad57",
  null
  
)

export default component.exports