
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { AddressModal, AddressModalComplete } from '@/components/shared/address-modal';
  import { Address } from '@/interfaces/Address';

  export default Vue.extend({
    name: 'es-client-addresses',

    components: {
      'es-address-modal': AddressModal,
      'es-address-modal-complete': AddressModalComplete,
    },

    data: () => ({
      isAlertModalOpen: false,
      isConfirmModalOpen: false,
      isAddressModalCompleteOpen: false,
      isAddressModalOpen: false,
      selectedAddress: {} as Address,
      modalTitle: '',
      modalMessage: '',
      method: '',
    }),

    computed: {
      ...mapGetters({ getAddresses: 'address/getAddresses' }),

      getConfirmationModalTitle(): string {
        return this.modalTitle;
      },

      getConfirmationModalMessage(): string {
        return this.modalMessage;
      },

      getConfirmationCta(): string {
        return this.method === 'select' ? this.$t('generic.confirm_cta').toString() : this.$t('address.delete').toString();
      },
    },

    watch: {
      isAddressModalCompleteOpen(newVal) {
        if (!newVal) {
          this.fetchAddresses();
        }
      },
    },

    created() {
      this.fetchAddresses();
    },

    methods: {
      ...mapActions({
        fetchAddresses: 'address/fetchAddresses',
        removeAddress: 'address/removeAddress',
        setDefaultAddress: 'address/setDefaultAddress',
      }),

      getAddress(address: Address): string {
        return `${address.street_name}, Nr.
              ${address.street_number}, ${this.$t(address.city.name)}`;
      },

      onEdit(address: Address): void {
        this.selectedAddress = address;
        this.isAddressModalCompleteOpen = true;
      },

      onSelect(address: Address): void {
        if (address.main) {
          return;
        }

        this.method = 'select';
        this.selectedAddress = address;
        this.modalTitle = this.$t('change.default.address.title').toString();
        this.modalMessage = `${this.$t('generic.set_question').toString()} ${this.getAddress(address)} ${this.$t('generic.set_primary_address').toString()}`;
        this.isConfirmModalOpen = true;
      },

      onRemove(address: Address): void {
        if (address.main) {
          this.isAlertModalOpen = true;
        } else {
          this.method = 'remove';
          this.selectedAddress = address;
          this.modalTitle = this.$t('generic.address_delete').toString();
          this.modalMessage = `${this.$t('generic.address_delete_question').toString()} ${this.getAddress(address)}?`;
          this.isConfirmModalOpen = true;
        }
      },

      async onContinue() {
        if (this.method === 'select') {
          await this.setDefaultAddress({
            id: this.selectedAddress.id,
            cityId: this.selectedAddress.city.id,
          });
        }

        if (this.method === 'remove') {
          await this.removeAddress(this.selectedAddress.id);
        }

        await this.fetchAddresses();
      },
    },
  });
