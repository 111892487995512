
  /* eslint-disable */
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { isEqual } from 'lodash-es';

  export default Vue.extend({
    name: 'es-client-account',

    data: () => ({
      user: {
        first_name: '',
        last_name: '',
        email: '',
      },
      account_settings: {
        send_app_notifications: false,
        sms_news: false,
        email_news: false,
        phone_news: false,
      },
    }),

    created() {
      const { first_name, last_name, email, account_setting } = this.getUser;
      this.user = { first_name, last_name, email };
      this.account_settings = { ...account_setting };
    },

    watch: {
      getUser(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          (this as any).$toasts.toast({
            id: 'update-toast',
            title: this.$t('toast.congrats_title'),
            message: this.$t('toast.account_update'),
            intent: 'success',
          });
        }
      },
    },

    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
    },

    methods: {
      ...mapActions({
        updateUser: 'session/updateUser',
      }),
      onSave(): void {
        this.updateUser(this.$data);
      },
    },
  });
