
  import Vue from 'vue';
  import { SIDEBAR_LINKS } from '@/constants/sidebar-links';
  import { Link } from '@/interfaces/Link';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'es-client-dashboard',
    data: () => ({
      clientSize: window.innerWidth,
    }),
    computed: {
      getSidebarLinks(): Link[] {
        return SIDEBAR_LINKS.map((item: Link) => ({ ...item, id: nanoid() }));
      },
    },
    created() {
      (this as any).$zendesk.load('4591939b-c8e2-4d8c-b9db-bb9e1b531846');
      window.addEventListener('resize', this.checkSize);
      this.checkSize();
    },
    methods: {
      checkSize() {
        this.clientSize = window.innerWidth;
      },
    },
  });
